.customerLogosContainer {
  @apply text-center px-5 lg:px-0 py-16 lg:py-14;
}

.customerLogos {
  @apply container mx-auto flex flex-col lg:flex-row gap-4 lg:gap-12 justify-center items-center mt-5 text-2xs place-content-between;
}

.customerLogos div {
  @apply flex flex-row gap-8 lg:gap-12 justify-center items-center mt-5 text-2xs place-content-between;
}
