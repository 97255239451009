.heroContainer {
  @apply br-container font-sans flex md:flex-row flex-col md:px-6;
}

.heroContent {
  @apply bg-gradient-to-r from-lighter-green-color to-light-green-color flex-grow text-dark-text-color pt-20 pb-32 pr-6 md:pr-16 pl-5 lg:pl-container-margin;
}

.heroContent.monosignPage {
  @apply bg-gradient-to-r from-monosign-hero-gradient-start to-monosign-hero-gradient-end text-lighter-green-color;
}

.heroContent.monopamPage {
  @apply bg-gradient-to-r from-monopam-hero-gradient-start to-monopam-hero-gradient-end text-lighter-purple-color;
}

.heroContent.monosyncPage {
  @apply bg-gradient-to-r from-monosync-hero-gradient-start to-monosync-hero-gradient-end text-lighter-orange-color;
}

.heroContent h1 {
  @apply text-4xl lg:text-5xl font-medium tracking-tightest lg:leading-66 leading-52;
}

.heroContent br {
  @apply hidden lg:inline;
}

.heroContent.monosignPage br,
.heroContent.monopamPage br,
.heroContent.monosyncPage br {
  @apply inline;
}

.heroContent p {
  @apply text-base lg:text-2xl font-normal tracking-tight mt-4 lg:mt-7 lg:leading-35 leading-6;
}

.heroCtaButtons {
  @apply flex flex-row flex-wrap gap-2.5 lg:gap-4 items-center mt-8 lg:mt-14;
}

.heroCtaButton {
  @apply bg-dark-text-color text-white px-4 py-2.5 lg:px-5 lg:py-3 rounded-xs font-semibold lg:text-base text-xs;
}
.heroCtaOutlineButton {
  @apply bg-clear text-dark-text-color border-2 border-dark-text-color box-border px-4 lg:px-5 py-2 lg:py-2.5 font-semibold rounded-xs text-xs lg:text-base;
}

.monosignPage .heroCtaButton {
  @apply bg-lighter-green-color text-monosign-shade;
}

.monosignPage .heroCtaOutlineButton {
  @apply text-lighter-green-color border-lighter-green-color;
}

.monopamPage .heroCtaButton {
  @apply bg-lighter-purple-color text-monopam-shade;
}

.monopamPage .heroCtaOutlineButton {
  @apply text-lighter-purple-color border-lighter-purple-color;
}

.monosyncPage .heroCtaButton {
  @apply bg-lighter-orange-color text-monosync-shade;
}

.monosyncPage .heroCtaOutlineButton {
  @apply text-lighter-orange-color border-lighter-orange-color;
}

.heroImage {
  @apply w-full lg:w-2/5 h-80 lg:h-auto flex-shrink-0;
  background: url(/images/monofor-hero-image.jpg) no-repeat 50% 50%;
  background-size: cover;
}

.heroImageWithoutBg {
  @apply heroImage w-full lg:w-2/4 h-80 lg:h-auto flex-shrink-0;
  background: none !important;
}

.contactFormContainer {
  @apply h-auto py-18 md:py-28 px-5 md:px-20 md:pr-container-margin bg-darker-gray-color flex-grow;
}

.brochureFormContainer {
  @apply bg-clear h-auto mt-5 md:mt-8 flex-grow;
}

.contactForm {
  @apply flex flex-col items-stretch gap-6;
}

.contactForm fieldset {
  @apply flex flex-col gap-2.5;
}

.contactForm label {
  @apply font-medium text-xs tracking-snug;
}

.contactForm input,
.contactForm textarea {
  @apply font-medium border border-lightest-gray-color rounded-xs text-lightest-gray-color text-2xs bg-clear p-3;
}

.brochureFormContainer.monosignBrochureFormContainer .contactForm input {
  @apply border-lighter-green-color text-lighter-green-color font-medium;
}

.brochureFormContainer.monopamBrochureFormContainer .contactForm input {
  @apply border-lighter-purple-color text-lighter-purple-color font-medium;
}

.brochureFormContainer .contactForm input {
  @apply border-lighter-green-color text-lighter-green-color font-medium;
}

.contactForm textarea {
  @apply h-72;
}

.contactForm input[type='submit'] {
  @apply cursor-pointer border-none bg-lightest-gray-color text-dark-text-color font-semibold py-2 mt-4 text-sm lg:text-base;
}

.brochureFormContainer .contactForm input[type='submit'] {
  @apply bg-lighter-green-color text-monosign-darker-shade mt-0;
}

.brochureFormContainer.monosignBrochureFormContainer
  .contactForm
  input[type='submit'] {
  @apply bg-lighter-green-color text-monosign-darker-shade;
}

.brochureFormContainer.monopamBrochureFormContainer
  .contactForm
  input[type='submit'] {
  @apply bg-lighter-purple-color text-monopam-darker-shade;
}

.contactForm input[type='submit'].disabled {
  @apply bg-gray-color text-white;
}

.contactForm input::-webkit-contacts-auto-fill-button {
  @apply bg-white;
}

.contactForm input::-webkit-credentials-auto-fill-button {
  @apply bg-white;
}

.contactForm input:-webkit-autofill::-webkit-contacts-auto-fill-button {
  @apply bg-dark-text-color;
}

.contactForm input:-webkit-autofill::-webkit-credentials-auto-fill-button {
  @apply bg-dark-text-color;
}

.contact .heroContent {
  @apply flex-grow-0 flex-shrink w-full md:w-6/12 md:pl-container-margin pr-6 md:pr-10 lg:pr-16 2xl:pr-32;
}

.contact .heroContent br {
  @apply hidden;
}

.company .heroContent {
  @apply bg-gradient-to-r from-lighter-green-color to-light-green-color flex-grow text-dark-text-color pt-20 pb-32 pr-6 md:pr-16 pl-5 lg:pl-container-margin;
}

.locationList {
  @apply mt-14;
}

.locationList h6 {
  @apply text-base font-medium mb-4;
}

.locationList ul {
  @apply flex flex-col gap-4;
}

.locationList ul li {
  @apply text-base font-semibold flex flex-row justify-start gap-2;
}

.locationList ul li svg {
  @apply mt-1 flex-shrink-0;
}

.locationList ul li div {
  @apply flex flex-col gap-0 tracking-tight;
}

.locationList ul li div p {
  @apply text-xs font-semibold mt-0 leading-23;
}

.locationList ul li div address {
  @apply text-xs font-medium leading-23;
}

.formFeedback span {
  @apply text-2xs font-medium text-left;
}

.formFeedback span.success {
  @apply text-light-green-color;
}

.monopamBrochureFormContainer .formFeedback span.success {
  @apply text-lighter-purple-color;
}

.formFeedback span.failure {
  @apply text-red;
}

.formFeedback span.none {
  @apply text-white text-2xs;
}

.headquarterItem {
  @apply mb-4;
}

fieldset.nameGroup {
  @apply flex flex-row content-stretch gap-2 md:gap-8;
}

fieldset.nameGroup input {
  @apply w-full;
}

fieldset.nameGroup div {
  @apply w-full flex flex-col gap-2.5 items-stretch;
}

fieldset.domainGroup {
  @apply flex flex-col gap-2.5 max-w-full;
}

fieldset.domainGroup div {
  @apply md:w-full flex flex-row gap-4 items-center border rounded-xs border-lightest-gray-color px-4;
}

fieldset.domainGroup div p {
  @apply block text-gray-color text-5xs md:text-2xs;
}

fieldset.domainGroup div input {
  @apply block w-0 min-w-0 flex-grow border-y-0 border-l border-r border-l-lightest-gray-color border-r-lightest-gray-color rounded-none;
}

.termsDisclaimer p {
  @apply text-4xs md:text-3xs;
}

.termsDisclaimer a {
  @apply underline font-medium;
}
