#finalCTA {
  @apply bg-background-color text-white;
}

.finalCTASection {
  @apply container mx-auto text-center flex flex-col items-center pt-14 lg:pt-16 pb-8 lg:pb-16;
}

.finalCTASection h3 {
  @apply text-3xl lg:text-4xl leading-35 lg:leading-52 font-medium tracking-tighter;
}

.finalCTASection p {
  @apply text-2xs lg:text-base mt-4 leading-21 lg:leading-28 font-normal tracking-snug px-9 lg:px-0;
}

.finalCTASection p br {
  @apply hidden lg:inline;
}

.finalCtaButtons {
  @apply flex flex-row gap-2.5 lg:gap-4 items-center mt-11 lg:mt-14 mb-14 lg:mb-0;
}

.finalCtaButton {
  @apply bg-white text-background-color px-4 py-2.5 lg:px-5 lg:py-3 rounded-xs font-semibold text-xs lg:text-base;
}

.finalCtaOutlineButton {
  @apply bg-clear text-white border-2 border-white box-border px-4 lg:px-5 py-2 lg:py-2.5 font-semibold rounded-xs text-xs lg:text-base;
}
